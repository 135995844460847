<script setup>
import { computed } from 'vue'

const model = defineModel()

const props = defineProps({
  label: String
})

const llabel = computed(() => props.label.toLowerCase())
</script>

<template>
  <div class="col-sm">
    <label :for="`select-${llabel}`" class="form-label">{{ label }}</label>
    <input class="form-control" :id="`select-${llabel}`" v-model="model" />
  </div>
</template>
