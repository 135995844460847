<script setup>
import CalendarFilterColumn from './CalendarFilterColumn.vue'
import CalendarFilterFreeSearch from './CalendarFilterFreeSearch.vue'
import { ref, computed, watch } from 'vue'

const emit = defineEmits(['update:filter'])

const handModels = JSON.parse(document.getElementById('hand_models').textContent)
const handSides = JSON.parse(document.getElementById('sides').textContent)
const handSizes = JSON.parse(document.getElementById('sizes').textContent)
const handColours = JSON.parse(document.getElementById('colours').textContent)
const handWristTypes = JSON.parse(document.getElementById('wrist_types').textContent)
const availabilityOptions = { true: 'Yes', false: 'No' }

const selectedModel = ref('')
const selectedSide = ref('')
const selectedSize = ref('')
const selectedColour = ref('')
const selectedWrist = ref('')
const selectedAvailability = ref('')
const searchTerm = ref('')

const filter = computed(() => {
  return {
    hand_model: selectedModel.value,
    side: selectedSide.value,
    size: selectedSize.value,
    colour: selectedColour.value,
    wrist_type: selectedWrist.value,
    available_today: selectedAvailability.value,
    search: searchTerm.value
  }
})

watch(filter, (val) => emit('update:filter', val))
</script>

<template>
  <div class="row col-12 my-3 p-3 bg-light rounded-3">
    <h3>Calendar</h3>
    <CalendarFilterColumn label="Model" :data="handModels" v-model="selectedModel" />
    <CalendarFilterColumn label="Side" :data="handSides" v-model="selectedSide" />
    <CalendarFilterColumn label="Size" :data="handSizes" v-model="selectedSize" />
    <CalendarFilterColumn label="Colour" :data="handColours" v-model="selectedColour" />
    <CalendarFilterColumn label="Wrist" :data="handWristTypes" v-model="selectedWrist" />
    <CalendarFilterColumn
      label="Available Now"
      :data="availabilityOptions"
      v-model="selectedAvailability"
    />
    <CalendarFilterFreeSearch label="Search" v-model="searchTerm" />
  </div>
</template>
