import '../styles/main.scss'
import App from './App.vue'
import Litepicker from 'litepicker'
import { createApp } from 'vue'

function updateFilters() {
  var model_select = document.querySelector('#select-model')
  if (model_select === null) {
    // No "model" selector, probably means we are on a "no results" screen, so no point filtering
    return
  }
  var model = model_select.value
  var side = document.querySelector('#select-side').value
  var size = document.querySelector('#select-size').value
  var colour = document.querySelector('#select-colour').value
  var wrist_type = document.querySelector('#select-wrist').value
  var available = document.querySelector('#select-available').value
  var search = document.querySelector('#free-filter').value
  var results = document.querySelector('div.results')

  if (results != null) {
    results.querySelectorAll('div.result-card').forEach((e) => {
      var shouldShow =
        (model == '' || e.dataset.hand_model == model) &&
        (side == '' || e.dataset.side == side) &&
        (size == '' || e.dataset.size == size) &&
        (colour == '' || e.dataset.colour == colour) &&
        (wrist_type == '' || e.dataset.wrist_type == wrist_type) &&
        (available == '' || available === e.dataset.available_today.toString()) &&
        (search == '' || e.dataset.label.toLowerCase().includes(search.toLowerCase()))
      e.style.display = shouldShow ? '' : 'none'
    })
  } else {
    calendarInstance.updateFilters({
      hand_model: model,
      side: side,
      size: size,
      colour: colour,
      wrist_type: wrist_type
    })
  }
}

function handResultsTable(hands, resultsTable, template, onSelect) {
  var sides = JSON.parse(document.getElementById('sides').textContent)
  var sizes = JSON.parse(document.getElementById('sizes').textContent)
  var colours = JSON.parse(document.getElementById('colours').textContent)
  var wrist_types = JSON.parse(document.getElementById('wrist_types').textContent)
  var hand_models = JSON.parse(document.getElementById('hand_models').textContent)

  hands.forEach((element) => {
    var row = document.importNode(template.content, true)
    var card = row.querySelector('div.result-card')
    row.querySelector('.col-serial').textContent = element.display_name
    row.querySelector('.col-model').textContent = hand_models[element.hand_model]
    row.querySelector('.col-side').textContent = sides[element.side]
    row.querySelector('.col-size').textContent = sizes[element.size]
    row.querySelector('.col-colour').textContent = colours[element.colour]
    row.querySelector('.col-wrist').textContent = wrist_types[element.wrist_type]

    var available_from = row.querySelector('.col-available-from')
    if (available_from) {
      available_from.textContent = element.first_available_day
    }

    card.dataset.hand_model = element.hand_model
    card.dataset.side = element.side
    card.dataset.size = element.size
    card.dataset.colour = element.colour
    card.dataset.wrist_type = element.wrist_type
    card.dataset.label = element.display_name
    card.dataset.available_today = element.available_today

    var button = row.querySelector('button')
    button.onclick = function () {
      onSelect(element)
    }

    resultsTable.appendChild(row)
  })
}

function handSearch() {
  var hands = JSON.parse(document.getElementById('available-hands').textContent)
  var template = document.querySelector('#result-card')
  var results = document.querySelector('div.results')

  handResultsTable(hands, results, template, function (element) {
    window.location.href = '/demohands/book/' + element.id + '/'
  })

  updateFilters()

  const query_date = document.querySelector('#datepicker').value
  const picker = new Litepicker({
    element: document.getElementById('datepicker'),
    minDate: new Date()
  })
}

function handBooking() {
  var hand = JSON.parse(document.getElementById('hand').textContent)

  const rangePicker = new Litepicker({
    element: document.querySelector("input[name='reservation_dates']"),
    singleMode: false,
    startDate: document.querySelector("input[name='delivery_date']").value,
    endDate: document.querySelector("input[name='return_date']").value,
    disallowLockDaysInRange: true,
    numberOfMonths: 2,
    numberOfColumns: 2,
    lockDays: hand.unavailable_days,
    setup: (picker) => {
      picker.on('selected', (date1, date2) => {
        document.querySelector("input[name='delivery_date']").value = date1.format('YYYY-MM-DD')
        startPicker.gotoDate(date1)
        document.querySelector("input[name='return_date']").value = date2.format('YYYY-MM-DD')
        endPicker.gotoDate(date2)
      })
    }
  })

  const endPicker = new Litepicker({
    element: document.querySelector("input[name='end_date']"),
    lockDays: hand.unavailable_days,
    lockDaysFilter: (date) => {
      var end_date = rangePicker.getEndDate()
      return end_date === null || date.isBefore(end_date)
    }
  })

  const startPicker = new Litepicker({
    element: document.querySelector("input[name='start_date']"),
    lockDays: hand.unavailable_days,
    lockDaysFilter: (date) => {
      var start_date = rangePicker.getStartDate()
      return start_date === null || date.isAfter(start_date)
    }
  })
}

function po_so_toggle() {
  var toggle = document.getElementById('id_paid_trial')
  toggle.onchange = po_so_on_toggle
  po_so_on_toggle()
}

function po_so_on_toggle() {
  var toggle = document.getElementById('id_paid_trial')
  var po_box = document.getElementById('id_purchase_order')
  var po_box_label = po_box.labels[0]

  if (toggle.checked) {
    po_box.disabled = false
    po_box_label.classList.add('requiredField')
    var asterisk = document.createElement('span')
    asterisk.textContent = '*'
    asterisk.classList.add('asteriskField')
    po_box_label.appendChild(asterisk)
  } else {
    po_box.disabled = true
    po_box.value = ''
    po_box_label.classList.remove('requiredField')
    var asterisk = po_box_label.getElementsByTagName('span')
    if (asterisk) {
      po_box_label.removeChild(po_box_label.getElementsByTagName('span')[0])
    }
  }
}

function changeHand() {
  var hands = JSON.parse(document.getElementById('available-hands').textContent)
  var template = document.querySelector('#result-card')
  var results = document.querySelector('div.results')

  handResultsTable(hands, results, template, function (element) {
    document.querySelector('#id_hand').value = element.id
    document.changeHand.submit()
  })
  updateFilters()
}

function changePool() {
  var newPool = document.querySelector('#select-pool').value
  document.cookie = 'active_pool=' + newPool + '; path=/'
  window.location = '/demohands/'
}

function initCalendar(data) {
  var app = createApp(App)

  app.mount('#calendar-app')
  return app
}

export {
  handSearch,
  updateFilters,
  handBooking,
  initCalendar,
  changeHand,
  po_so_toggle,
  changePool
}
