<script setup>
import { computed } from 'vue'

const model = defineModel()

const props = defineProps({
  label: String,
  data: Object
})

const llabel = computed(() => props.label.toLowerCase())
</script>

<template>
  <div class="col-sm">
    <label :for="`select-${llabel}`" class="form-label">{{ label }}</label>
    <select class="form-select" :id="`select-${llabel}`" v-model="model">
      <option value="">Any</option>
      <option :value="key" v-for="(value, key) in data">
        {{ value }}
      </option>
    </select>
  </div>
</template>
