<script setup>
import Calendar from './components/Calendar.vue'
import CalendarFilter from './components/CalendarFilter.vue'
import { ref } from 'vue'

const filters = ref({
  hand_model: '',
  side: '',
  size: '',
  colour: '',
  wrist_type: ''
})

const updateFilters = (e) => {
  filters.value = e
}
</script>

<template>
  <CalendarFilter @update:filter="updateFilters" />
  <Calendar :filters="filters" />
</template>
