<template>
  <div>
    <div class="calendar-wrapper">
      <div class="date-column">
        <div class="month-controls fixed-left d-flex align-items-center justify-content-center">
          <button class="btn btn-dark" @click="offset -= 1">&lt; Prev</button>
          <span class="px-1"></span>
          <button class="btn btn-dark" @click="offset += 1">Next ></button>
        </div>
        <div class="month-controls-spacer fixed-left"></div>

        <div class="calendar-currmonth-heading">
          {{ formatMonth(firstMonth) }}
        </div>
        <div class="calendar-nextmonth-heading">
          {{ formatMonth(secondMonth) }}
        </div>

        <div
          v-for="(date, i) in calendarDates"
          :key="`cal-${i}`"
          class="calendar-day-heading"
          :class="{ highlight: todayCol === i, monthBorder: isLastOfMonth(i) }"
          :style="calendarDayHeading(i)"
          v-html="formatDate(date)"
        ></div>

        <template v-for="(hand, i) in filteredBookings">
          <div :style="handRowLabelCell(i)" class="fixed-left" :class="handClass(hand)">
            <a :href="`/demohands/book/${hand.id}/`">
              {{ hand.display_name }}<br />
              {{ hand.type_label }}
            </a>
          </div>
          <div
            :class="{
              highlight: todayCol === n - 1,
              monthBorder: isLastOfMonth(n - 1)
            }"
            v-for="n in 62"
            :key="`${hand.serial_number}-${n}`"
            class="calendar-cell"
            :style="cellStyle(n, i)"
          ></div>

          <div
            :style="bookingStyle(booking, i)"
            :class="bookingClass(booking)"
            v-for="(booking, j) in visibleBookings(hand.reservations)"
            :key="`${hand.serial_number}-bookings-${j}`"
          >
            <a :href="`/demohands/reservation/${booking.id}/`">
              <span v-if="booking.customer != ''">
                {{ booking.customer }}<br />
                {{ booking.label }}
              </span>
            </a>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  endOfDay,
  min,
  max,
  startOfMonth,
  isSameDay,
  isLastDayOfMonth,
  add,
  getDaysInMonth,
  differenceInDays,
  format,
  parseISO
} from 'date-fns'
import { ref, computed, defineProps } from 'vue'

const bookings = JSON.parse(document.getElementById('calendar-data').textContent)

const props = defineProps({
  filters: Object
})

const offset = ref(0)
const thisMonth = ref(startOfMonth(new Date()))
const leftColWidth = 6

const formatDate = (date) => format(date, "eee'<br />'dd") // Format a date for display in the calendar
const formatMonth = (date) => format(date, 'MMMM yyyy') // Format a date for displaying the month name
function bookingStyle(booking, i) {
  // Dynamically create the CSS style for a particular booking.
  // Most importantly, this calculates the grid areas in which the bookings need to be
  // displayed, based on date (the horizontal position --> columns) and index of hand in bookings
  // array (the vertical position --> row)

  // Clip the booking dates to visible date range
  const startDate = max([calendarDates.value.at(0), parseISO(booking.start_date)])
  const endDate = min([endOfDay(calendarDates.value.at(-1)), parseISO(booking.end_date)])

  // Offset the display row by 3 to account for both 0-index (js) vs 1-index (CSS), and for the fact
  // that the first two CSS rows are used to display the calendar header.
  const row = 3 + i

  // Offset the start column by 1 to account for 0-index (js) vs 1-index (CSS)
  const startCol = 1 + calendarDates.value.findIndex((d) => isSameDay(d, startDate)) + leftColWidth

  // Offset by one because CSS span requires to go to the start of the next cell.
  const colSpan = differenceInDays(endDate, startDate) + 1

  return {
    gridArea: `${row} / ${startCol} / ${row} / span ${colSpan}`
  }
}
function bookingClass(booking) {
  var classNames = `booking booking-${booking.reservation_type.replaceAll(' ', '-').toLowerCase()}`
  if (booking.is_exception_state) {
    classNames += ' booking-exception'
  }
  return classNames
}
function cellStyle(n, i) {
  // Dynamically create CSS style for our calendar cells, taking care of positioning
  // the cell within the CSS grid.
  const row = 3 + i // Offset by 3, see inline comment in `bookingStyle` method
  return {
    gridArea: `${row} / ${n + leftColWidth} / ${row} / span 1`
  }
}
function calendarDayHeading(i) {
  // Calculate the style for the "day" header
  // Need to offset column by 1 (0-based array indexing) and leftColWidth
  return { gridArea: `2 / ${i + leftColWidth + 1} / 2` }
}
function handRowLabelCell(i) {
  // Calculate the style for the cell with the hand label
  return { gridArea: `${i + 3} / 1 / ${i + 3} / span ${leftColWidth}` }
}
function visibleBookings(bookings) {
  // Filter bookings based on visible date range
  return bookings.filter((booking) => {
    return (
      parseISO(booking.start_date) <= endOfDay(calendarDates.value.at(-1)) &&
      parseISO(booking.end_date) >= calendarDates.value.at(0)
    )
  })
}
function isLastOfMonth(index) {
  return isLastDayOfMonth(calendarDates.value.at(index))
}

function shouldDisplay(hand) {
  for (const [key, value] of Object.entries(props.filters)) {
    if (key === 'search') {
      return hand['display_name'].toLowerCase().includes(value.toLowerCase())
    } else if (value != '' && hand[key].toString() != value) {
      return false
    }
  }
  return true
}

const filteredBookings = computed(() => bookings.filter(shouldDisplay))

const firstMonth = computed(() => {
  // Return the name of the left month
  return add(thisMonth.value, { months: offset.value })
})
const secondMonth = computed(() => {
  // Return the name of the right month
  return add(thisMonth.value, { months: offset.value + 1 })
})
const todayCol = computed(() => {
  // Return the index of the today in the array of visible dates
  return calendarDates.value.findIndex((date) => isSameDay(date, new Date()))
})
const calendarDates = computed(() => {
  // Return an array(62) of visible dates
  const currMonth = add(thisMonth.value, { months: offset.value })
  const startDate = add(currMonth, {
    days: getDaysInMonth(currMonth) - 31
  })
  return Array(62)
    .fill()
    .map((_, i) => add(startDate, { days: i }))
})
function handClass(hand) {
  var className = 'calendar-hand-column'
  className += ` hand-${hand.status.replaceAll(' ', '-').toLowerCase()}`
  if (hand.current_reservation) {
    className += ` hand-res-${hand.current_reservation.reservation_type.replaceAll(' ', '-').toLowerCase()}`
  }
  return className
}
</script>

<style>
.calendar-wrapper {
  overflow-x: scroll;
}

.hand-column {
  display: grid;
  grid-template-rows: 1fr repeat(2, 100px);
  background-color: white;
}

.header {
  position: sticky;
  z-index: 1;
}

.fixed-left {
  z-index: 2;
  position: sticky;
  left: 0;
  background-color: white;
}

.date-column {
  display: grid;
  grid-template-columns: repeat(68, minmax(30px, 1fr));
  width: 100%;
}

.month-controls {
  grid-area: 1 / 1 / 1 / span 6;
}

.month-controls-spacer {
  grid-area: 2 / 1 / 2 / span 6;
}

.calendar-currmonth-heading {
  grid-column: 7 / span 31;
  padding: 0.3rem;
}

.calendar-nextmonth-heading {
  grid-column: span 31;
  padding: 0.3rem;
}

.calendar-day-heading {
  text-align: center;
  font-size: 0.8rem;
  color: grey;
}

.highlight {
  background-color: rgba(255, 215, 0, 0.2);
  z-index: 1;
}

.calendar-hand-column {
  height: 100px;
  border-right: 2px solid rgb(129, 120, 120);
  padding-left: 8px;
  display: flex;
  align-items: center;
}

.calendar-cell {
  text-align: center;
  font-size: 0.8rem;
  height: 100px;
  color: grey;
  border-right: 0.5px dotted grey;
}

.booking {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0.5rem;
  border-radius: 0.4rem;
  color: black;
  font-size: 0.8rem;
  height: 64px;
}

.booking-trial {
  background-color: rgb(199, 77, 77);
}

.booking-long-term-loaner {
  background-color: rgb(209, 197, 87);
}

.booking-loan-for-repair-service {
  background-color: rgb(85, 106, 221);
}

.booking-demo-hand-under-repair {
  background-color: rgb(124, 135, 142);
}

.booking-event-marketing {
  background-color: rgb(199, 46, 199);
}
.booking-out-with-taska-staff {
  background-color: rgb(180, 198, 231);
}
.booking-exception {
  border: 3px solid blue;
}
.hand-ready {
  background-color: rgb(102, 167, 101);
}
.hand-with-customer.hand-res-long-term-loaner {
  background-color: rgb(209, 197, 87);
}
.hand-with-customer {
  background-color: rgb(139, 134, 134);
}
.hand-returned-from-customer {
  background-color: rgb(168, 108, 218);
}

.monthBorder {
  border-right: 1px solid rgb(129, 120, 120);
}
</style>
